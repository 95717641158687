export const getBetStatus = (bet) => {
    const status = {
        draw: "status-circle bg-info status",
        won: "status-circle bg-success status",
        lost: "status-circle bg-danger status",
        default: "spinner spinner-grow spinner-grow-sm status",
    };

    if (!('payout' in bet)) return status.default;
    else if (bet.payout === bet.stake) return status.draw;
    else if (bet.payout > bet.stake) return status.won;
    else return status.lost;
}