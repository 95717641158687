import { fetch, fetchBlob } from '../utils';

export const createUserRepository = () => {
    const create = data => fetch({
        method: 'post',
        path: 'arbs/users',
        data
    })

    const getActiveSubscription = () => fetch({
        method: "get",
        path: "arbs/subscriptions",
        headers: {
            Authorization: localStorage.jwt,
        },
    })

    const createSubscription = () => fetch({
        method: "post",
        path: "arbs/subscriptions",
        headers: {
            Authorization: localStorage.jwt,
        },
    })

    const cancelSubscription = subscriptionId => fetch({
        method: "post",
        path: `arbs/subscriptions/${subscriptionId}/cancel`,
        headers: {
            Authorization: localStorage.jwt,
        },
    })

    const freezeSubscription = subscriptionId => fetch({
        method: "put",
        path: `arbs/subscriptions/${subscriptionId}/freeze`,
        headers: {
            Authorization: localStorage.jwt,
        },
    })

    const unfreezeSubscription = subscriptionId => fetch({
        method: "put",
        path: `arbs/subscriptions/${subscriptionId}/unfreeze`,
        headers: {
            Authorization: localStorage.jwt,
        },
    })

    const getAvailableSubscriptions = () => fetch({
        method: "get",
        path: "arbs/products",
        headers: {
            Authorization: localStorage.jwt,
        },
    });

    const getAddresses = () => fetch({
        method: "get",
        path: "arbs/addresses",
        headers: {
            Authorization: localStorage.jwt,
        },
    });

    const createAddress = (address) => fetch({
        method: "post",
        path: "proxies/detach",
        headers: {
            Authorization: localStorage.jwt,
        },
        data: address
    })

    const loadSettingsOptions = () => fetch({
        method: "get",
        path: "arbs/settings/options",
        headers: { Authorization: localStorage.jwt },
    })

    
    const loadSettings = (version) => fetch({
        method: "get",
        path: `arbs/settings?version=${version}`,
        headers: { Authorization: localStorage.jwt },
    })

    // Joi.object({
    //     name: Joi.string().max(80).required(),
    //     defaultConfiguration: Joi.boolean().required(),
    //     version: Joi.string().max(10).required(),
    //     configuration: Joi.object({
    //         bookies: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.bookies.map(({ bookie }) => bookie))).required(),
    //         bookiesReference: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.bookies.map(({ bookie }) => bookie))).required(),
    //         sports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //         sportsMarkets: Joi.object().keys({
    //             ...cconfig.service.get().streaming.settings.sports.reduce((t, c) => ({ ...t, [c.sport]: Joi.array().items(Joi.string().max(20)) }), {}),
    //         }).required(),
    //         nextPeriodSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //         pausedSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //         middleSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //         minPercentage: Joi.number().min(-100).max(100).required(),
    //         maxPercentage: Joi.number().min(-100).max(100).required(),
    //         minTime: Joi.number().min(0).required(),
    //         maxTime: Joi.number().greater(Joi.ref('minTime')).required(),
    //         filterOverTimeMismatch: Joi.boolean().required(),
    //         showTimeSinceLastUpdate: Joi.boolean().required(),
    //         showNextPeriodExtraBet: Joi.boolean().required(),
    //         excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
    //         subscriptionConfigs: Joi.object().pattern(/\w{2,50}/, Joi.object({
    //             sports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //             sportsMarkets: Joi.object().keys({
    //                 ...cconfig.service.get().streaming.settings.sports.reduce((t, c) => ({ ...t, [c.sport]: Joi.array().items(Joi.string().max(20)) }), {}),
    //             }).required(),
    //             nextPeriodSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //             pausedSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //             middleSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //             minPercentage: Joi.number().min(-100).max(100).required(),
    //             maxPercentage: Joi.number().min(-100).max(100).required(),
    //             minTime: Joi.number().min(0).required(),
    //             maxTime: Joi.number().greater(Joi.ref('minTime')).required(),
    //             filterOverTimeMismatch: Joi.boolean().required(),
    //             excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
    //         })).required(),
    //     }),
    // })
    const saveSettings = (settings) => fetch({
        method: "post",
        path: "arbs/settings",
        headers: { Authorization: localStorage.jwt },
        data: settings
    })

    // Same payload as saveSettings
    const updateSettings = (id, settings) => fetch({
        method: "put",
        path: `arbs/settings/${id}`,
        headers: { Authorization: localStorage.jwt },
        data: settings
    })

    const deleteSettings = (id) => fetch({
        method: "delete",
        path: `arbs/settings/${id}`,
        headers: { Authorization: localStorage.jwt },
    })

    const getExtensionConfiguration = () => fetch({
        method: "get",
        path: "arbs/extension",
        headers: {
            Authorization: localStorage.jwt,
        },
    });

    const loadSettingsOptionsV2 = (type) => fetch({
        path: `v2/settings/options/${type}`,
        headers: { Authorization: localStorage.jwt },
    })

    
    const loadSettingsV2 = (type, version = 2) => fetch({
        method: "get",
        path: `v2/settings/${type}?version=${version}`,
        headers: { Authorization: localStorage.jwt },
    })

    const getCoupons = () => fetch({
        method: "get",
        path: `coupons/self`,
        headers: { Authorization: localStorage.jwt },
    })

    const redeemCoupon = (code) => fetch({
        method: "post",
        path: `coupons/redeem`,
        headers: { Authorization: localStorage.jwt },
        data: { code }
    })

    const redeemPoints = (points) => fetch({
        method: "post",
        path: `coupons/redeem-points`,
        headers: { Authorization: localStorage.jwt },
        data: { points }
    })

    // For type = live
    // Joi.object({
    //     name: Joi.string().max(80).required(),
    //     defaultConfiguration: Joi.boolean().required(),
    //     version: Joi.string().max(10).required(),
    //     configuration: Joi.object({
    //         bookies: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.bookies.map(({ bookie }) => bookie))).required(),
    //         bookiesReference: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.bookies.map(({ bookie }) => bookie))).required(),
    //         sports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //         sportsMarkets: Joi.object().keys({
    //             ...cconfig.service.get().streaming.settings.sports.reduce((t, c) => ({ ...t, [c.sport]: Joi.array().items(Joi.string().max(20)) }), {}),
    //         }).required(),
    //         nextPeriodSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //         pausedSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //         middleSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //         minPercentage: Joi.number().min(-100).max(100).required(),
    //         maxPercentage: Joi.number().min(-100).max(100).required(),
    //         minTime: Joi.number().min(0).required(),
    //         maxTime: Joi.number().greater(Joi.ref('minTime')).required(),
    //         filterOverTimeMismatch: Joi.boolean().required(),
    //         excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
    //         sortBy: Joi.string().valid(cconfig.service.get().pre.settings.sortings.map(({ type }) => type)).required(),
    //         subscriptionConfigs: Joi.object().pattern(/\w{2,50}/, Joi.object({
    //             sports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //             sportsMarkets: Joi.object().keys({
    //                 ...cconfig.service.get().streaming.settings.sports.reduce((t, c) => ({ ...t, [c.sport]: Joi.array().items(Joi.string().max(20)) }), {}),
    //             }).required(),
    //             nextPeriodSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //             pausedSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //             middleSports: Joi.array().items(Joi.string().valid(cconfig.service.get().streaming.settings.sports.map(({ sport }) => sport))).required(),
    //             minPercentage: Joi.number().min(-100).max(100).required(),
    //             maxPercentage: Joi.number().min(-100).max(100).required(),
    //             minTime: Joi.number().min(0).required(),
    //             maxTime: Joi.number().greater(Joi.ref('minTime')).required(),
    //             filterOverTimeMismatch: Joi.boolean().required(),
    //             excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
    //         })).required(),
    //     }),
    // })
    //
    // For type = pre
    // Joi.object({
    //     name: Joi.string().max(80).required(),
    //     defaultConfiguration: Joi.boolean().required(),
    //     version: Joi.string().max(10).required(),
    //     configuration: Joi.object({
    //         bookies: Joi.array().items(Joi.string().valid(cconfig.service.get().pre.settings.bookies.map(({ bookie }) => bookie))).required(),
    //         bookiesReference: Joi.array().items(Joi.string().valid(cconfig.service.get().pre.settings.bookies.map(({ bookie }) => bookie))).required(),
    //         sports: Joi.array().items(Joi.string().valid(cconfig.service.get().pre.settings.sports.map(({ sport }) => sport))).required(),
    //         sportsMarkets: Joi.object().keys({
    //             ...cconfig.service.get().pre.settings.sports.reduce((t, c) => ({ ...t, [c.sport]: Joi.array().items(Joi.string().max(20)) }), {}),
    //         }).required(),
    //         minPercentage: Joi.number().min(-100).max(100).required(),
    //         maxPercentage: Joi.number().min(-100).max(100).required(),
    //         filterOverTimeMismatch: Joi.boolean().required(),
    //         excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
    //         sortBy: Joi.string().valid(cconfig.service.get().pre.settings.sortings.map(({ type }) => type)).required(),
    //         subscriptionConfigs: Joi.object().pattern(/\w{2,50}/, Joi.object({
    //             sports: Joi.array().items(Joi.string().valid(cconfig.service.get().pre.settings.sports.map(({ sport }) => sport))).required(),
    //             sportsMarkets: Joi.object().keys({
    //                 ...cconfig.service.get().pre.settings.sports.reduce((t, c) => ({ ...t, [c.sport]: Joi.array().items(Joi.string().max(20)) }), {}),
    //             }).required(),
    //             minPercentage: Joi.number().min(-100).max(100).required(),
    //             maxPercentage: Joi.number().min(-100).max(100).required(),
    //             filterOverTimeMismatch: Joi.boolean().required(),
    //             excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
    //         })).required(),
    //     }),
    // });
    const saveSettingsV2 = (type, settings) => fetch({
        method: "post",
        path: `v2/settings/${type}`,
        headers: { Authorization: localStorage.jwt },
        data: settings
    })

    // Same payload as saveSettings
    const updateSettingsV2 = (type, id, settings) => fetch({
        method: "put",
        path: `v2/settings/${type}/${id}`,
        headers: { Authorization: localStorage.jwt },
        data: settings
    })

    const deleteSettingsV2 = (id) => fetch({
        method: "delete",
        path: `arbs/settings/${id}`,
        headers: { Authorization: localStorage.jwt },
    })

    const getUserBets = email => fetch({
        method: 'get',
        path: `users/${encodeURIComponent(email)}`,
        headers: {
            Authorization: localStorage.jwt
        },
    })

    const getBookies = email => fetch({
        method: 'get',
        path: `users/${email}/bookies/list`,
        headers: {
            Authorization: localStorage.jwt
        }
    })

    const getComplexBookies = () => fetch({
        method: 'get',
        path: `bookies/complex`,
        headers: {
            Authorization: localStorage.jwt
        }
    })

    const getNotifications = () => fetch({
        method: 'get',
        path: `administrators/notifications`,
        headers: {
            Authorization: localStorage.jwt
        }
    })

    const getUsersBilling = () => fetch({
        method: 'get',
        path: 'billing/users',
        headers: {
            Authorization: localStorage.jwt
        }
    })

    const getUsersUpgrade = () => fetch({
        method: 'get',
        path: 'users/upgrade',
        headers: {
            Authorization: localStorage.jwt
        }
    })

    const addCredit = (email, data) => fetch({
        method: 'POST',
        path: `users/${email}/payment`,
        headers: {
            Authorization: localStorage.jwt
        },
        data
    });

    const transferCredit = (email, amount) => fetch({
        method: 'POST',
        path: `users/${email}/payment`,
        headers: {
            Authorization: localStorage.jwt
        },
        data: {
            amount,
            type: "deposit"
        }
    });

    const confirmPayment = (email, paymentId) => fetch({
        method: 'PUT',
        path: `users/${email}/payment/${paymentId}/confirm`,
        headers: {
            Authorization: localStorage.jwt
        },
    });

    const addCorrection = (email, data) => fetch({
        method: 'POST',
        path: `users/${email}/correction`,
        headers: {
            Authorization: localStorage.jwt
        },
        data
    })

    const refreshBalance = email => fetch({
        method: 'POST',
        path: `users/${email}/balance/refresh`,
        headers: {
            Authorization: localStorage.jwt
        },
    })

    const upgradeUser = (email, version) => fetch({
        method: 'POST',
        path: `users/${email}/upgrade`,
        headers: {
            Authorization: localStorage.jwt
        },
        data: {
            version
        }
    })

    const addReferral = (email, referredBy) => fetch({
        method: 'PUT',
        path: `users/${email}/referral/${referredBy}`,
    })

    const changeProxyMode = (email, mode) => fetch({
        method: 'PUT',
        path: `users/${email}/proxy`,
        data: {
            mode,
        }
    })

    const changeTimingMode = (email, mode) => fetch({
        method: 'PUT',
        path: `users/${email}/timing`,
        data: {
            mode,
        }
    })

    const disableEmail = email => fetch({
        method: 'PUT',
        path: `users/${email}/disable`,
        headers: {
            authorization: localStorage.jwt,
        },
    });

    const enableEmail = email => fetch({
        method: "put",
        path: `users/${email}/enable`,
        headers: {
            authorization: localStorage.jwt,
        },
    });

    const setReadyStatus = (email, status) => fetch({
        method: "put",
        path: `users/${email}/ready`,
        headers: {
            authorization: localStorage.jwt,
        },
        data: {
            status,
        },
    });

    const toggleBookieStatus = (email, bookie, action) => {
        return fetch({
            method: 'PUT',
            path: `users/${email}/bookie/${bookie}/${action}`,
            headers: {
                'authorization': localStorage.getItem('jwt')
            }
        })
    }

    const setActivationDate = (email, name, startDate) => fetch({
        method: 'PUT',
        path: `users/${email}/bookie/${name}/activation`,
        headers: {
            authorization: localStorage.getItem('jwt')
        },
        data: {
            date: startDate
        }
    });

    const markNotificationSeen = (error) => fetch({
        method: "post",
        path: "administrators/notifications/seen",
        headers: {
            Authorization: localStorage.jwt,
        },
        data: {
            error: {
                email: error.email,
                type: error.type,
                bookie: error.bookie,
                status: error.status,
            },
        },
    });

    const bookieAction = async (email, bookie, action) => fetch({
        method: "PUT",
        path: `users/${email}/bookie/${bookie}/${action}`,
        headers: {
            authorization: localStorage.jwt,
        },
    });

    const settleBet = (email, bet) => fetch({
        method: 'PUT',
        path: `users/${email}/bet/${bet.version}/settle`,
        headers: {
            authorization: localStorage.jwt
        },
    });

    const addBookie = ({ email, bookie, action, username, password, enable, date, birthdate }) => {
        return fetch({
            method: 'post',
            path: `users/${email}/bookies/${bookie}/${action}`,
            headers: {
                Authorization: localStorage.jwt
            },
            data: {
                username: username && username.trim(),
                password: password && password.trim(),
                enable,
                birthdate,
                date
            }
        })
    }

    const getChapters = () => fetch({
        path: `academy/chapters`,
        headers: {
            authorization: localStorage.jwt
        },
    });

    const getBookieCredentials = (email, bookie) => fetch({
        path: `users/${email}/bookie/${bookie}/credentials`,
        headers: {
            authorization: localStorage.getItem('jwt')
        },
    });

    const getBotHome = () => fetch({
        path: 'bot/home',
    });

    const getInvoices = () => fetch({
        path: 'billing/invoices',
        headers: {
            Authorization: localStorage.jwt
        }
    });

    const getInvoice = (id) => fetchBlob({
        path: `billing/invoices/${id}`,
        headers: {
            Authorization: localStorage.jwt
        },
    })

    const navigateToArb = (arb) => fetch({
        method: "post",
        path: `arbs/navigate`,
        headers: { Authorization: localStorage.jwt },
        data: arb
    })

    const executeArb = (arb) => fetch({
        method: "post",
        path: `arbs/execute/arb`,
        headers: { Authorization: localStorage.jwt },
        data: arb
    })

    const executeValuebet = (arb) => fetch({
        method: "post",
        path: `arbs/execute/valuebet`,
        headers: { Authorization: localStorage.jwt },
        data: arb
    })
        
    return {
        create,
        getActiveSubscription,
        cancelSubscription,
        freezeSubscription,
        unfreezeSubscription,
        createSubscription,
        getAvailableSubscriptions,
        getAddresses,
        createAddress,
        loadSettingsOptions,
        loadSettings,
        saveSettings,
        updateSettings,
        deleteSettings,
        loadSettingsOptionsV2,
        loadSettingsV2,
        saveSettingsV2,
        updateSettingsV2,
        deleteSettingsV2,
        getExtensionConfiguration,
        getUserBets,
        getBookies,
        getNotifications,
        getUsersBilling,
        getUsersUpgrade,
        addCredit,
        transferCredit,
        confirmPayment,
        addCorrection,
        refreshBalance,
        upgradeUser,
        addReferral,
        changeProxyMode,
        changeTimingMode,
        disableEmail,
        enableEmail,
        toggleBookieStatus,
        setActivationDate,
        markNotificationSeen,
        bookieAction,
        settleBet,
        addBookie,
        getComplexBookies,
        getBookieCredentials,
        getChapters,
        getBotHome,
        setReadyStatus,
        getInvoices,
        getInvoice,
        navigateToArb,
        executeArb,
        executeValuebet,
        redeemCoupon,
        redeemPoints,
        getCoupons
    };
}

