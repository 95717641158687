import { fetch } from '../utils';

export const createAuthRepository = () => {
     const login = (emailFromInput) => fetch({
        method: 'post',
        path: 'arbs/login',
        data: {
            email: emailFromInput.toLowerCase().trim()
        }
    });

    const getUserInfo = () => fetch({
        method: 'get',
        path: 'administrators/me',
        headers: {
            Authorization: localStorage.jwt
        },
    });

     const getAdministrators = () => fetch({
        method: 'get',
        path: 'administrators',
        headers: {
            Authorization: localStorage.jwt
        },
    });

    return {
        login,
        getUserInfo,
        getAdministrators,
    }
}
