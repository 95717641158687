export const createAuthService = (authRepository) => {
    const login = emailFromInput => authRepository.login(emailFromInput)

    const getUserInfo = () => authRepository.getUserInfo()

    const getAdministrators = () => authRepository.getAdministrators()

    return {
        login,
        getUserInfo,
        getAdministrators,
    }
}
